.clients-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}
