.negative-value {
  color: rgba(var(--bs-danger-rgb), 1) !important;
}

.positive-value {
  color: rgba(var(--bs-success-rgb), 1) !important;
}

.contract-total-value {
  color: rgba(var(--bs-success-rgb), 1) !important;

  &:hover {
    cursor: pointer;
  }
}

.recurring-withdrawal {
  color: rgba(var(--bs-success-rgb), 1) !important;
}
