.action-button {
  position: fixed;
  width: 30px;
  right: 4rem;
  bottom: 4rem;
  z-index: 99;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &__button--close {
    background-color: $danger;
  }

  &__button--transfer {
    background-color: $success;
  }

  &__button i {
    font-size: 24px;
    color: white;
    font-weight: bold;
  }
}
