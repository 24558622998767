.logo-pdf {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }

  body {
    margin: 0.5cm;
  }

  * {
    box-shadow: none !important;
  }

  .hidden-print {
    display: none !important;
  }

  .card-title {
    display: flex;
    align-items: center;
    font-family: "Mundial";
    font-weight: 400;
    font-size: 16px;
  }

  .logo-pdf {
    display: block;
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #ddd;

    h1 {
      font-family: "Mundial";
      font-size: 24px;
      font-weight: 600;
      font-size: normal;
      color: rgb(0, 0, 0);
      margin: 0;
    }

    h2 {
      font-family: "Mundial";
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      text-transform: uppercase;
      color: rgb(0, 0, 0);
      margin: 0;
    }
  }

  form,
  .nav {
    display: none;
  }

  .font-size-14 {
    font-size: 12px !important;
  }

  .bg-light {
    font-size: 10px !important;
    color: rgba($light, 1) !important;
  }

  .bg-success {
    font-size: 10px !important;
    color: rgba($success, 1) !important;
  }

  .bg-danger {
    font-size: 10px !important;
    color: rgba($danger, 1) !important;
  }

  .bg-primary {
    font-size: 10px !important;
    color: rgba($primary, 1) !important;
  }

  .extract__identification-number {
    color: $dark !important;
  }

  .extracts__real-value-column {
    display: none;
  }
}
