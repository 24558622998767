.extracts {
  tr {
    border-bottom-width: 1px;
  }

  td {
    border-right-width: 1px !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px;

    &:last-child {
      border-right: 0 !important;
    }
  }

  p {
    margin: 0;
  }

  &__real-value-column {
    width: 200px;
  }
}

body[data-layout-mode="dark"] {
  .text-body-tertiary {
    color: rgb(246, 246, 246) !important;
  }
}
