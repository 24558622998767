.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 70px;
  z-index: 999;

  &:hover {
    height: 100%;

    .sub-button:nth-child(2) {
      transform: translateY(-42px);
    }

    .sub-button:nth-child(3) {
      transform: translateY(-80px);
    }
  }

  .fab {
    position: relative;
    height: 48px;
    width: 48px;
    background-color: $primary;
    border-radius: 50%;
    z-index: 2;

    .fab-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);

      i {
        color: white;
        font-size: 32px;
        font-weight: bold;
      }
    }
  }

  .sub-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 20px;
    height: 32px;
    width: 32px;
    background-color: $primary;
    border-radius: 50%;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

    &__primary {
      background-color: $primary;
    }

    &__danger {
      background-color: $danger;
    }

    &__success {
      background-color: $success;
    }

    &:hover {
      cursor: pointer;
    }

    i {
      font-size: 24px;
      color: white;
      font-weight: bold;
    }
  }
}
