@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

@font-face {
  font-family: "Mundial";
  src: url("../../../fonts/mundial/MundialBold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mundial";
  src: url("../../../fonts/mundial/MundialThin.otf");
  font-weight: 300;
  font-style: normal;
}
