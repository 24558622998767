.react-select-container {
  &--z-index-max {
    z-index: 9999999999;
  }

  div {
    background-color: $form-select-bg;
    border-color: $form-select-border-color !important;
  }

  .react-select-multi-value > div,
  .react-select-placeholder,
  .react-select-single-value {
    background-color: transparent !important;
    z-index: 9999999;
  }

  &.is-invalid > div {
    border-color: #f46a6a !important;
  }
}

body[data-layout-mode="dark"] {
  .react-select-container {
    div {
      background-color: $form-select-bg !important;
      color: $form-select-color !important;
    }

    & > div {
      border-color: $form-select-border-color !important;
    }

    &.is-invalid > div {
      border-color: #f46a6a !important;
    }
  }
}
