.steps {
  display: flex;
  justify-content: center;

  &__item {
    display: flex;
    align-items: center;
    list-style: none;
    transition: all 0.3s ease-in-out;

    &::after {
      content: "";
      width: 16px;
      height: 0px;
      border: solid 0.5px $gray-600;
    }

    &::before {
      content: "";
      width: 16px;
      height: 0px;
      border: solid 0.5px $gray-600;
    }

    &:first-child::before {
      width: 0;
      border: none;
    }

    &:last-child::after {
      width: 0;
      border: none;
    }
  }

  &__link {
    color: $gray-600;
    transition: all 0.3s ease-in-out;

    & div {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 32px;
      border-radius: 8px;
      padding: 0 8px;
      border: solid 1px $gray-600;
    }
  }
}

.steps .steps__item--active {
  &::after {
    border-color: $blue;
  }

  &::before {
    border-color: $blue;
  }

  .steps__link {
    color: $white;

    div {
      border-color: $blue;
      background-color: $blue;
    }
  }
}

.steps .steps__item--finished {
  &::after {
    border-color: $green;
  }

  &::before {
    border-color: $green;
  }

  .steps__link {
    color: $white;

    div {
      border-color: $green;
      background-color: $green;
    }
  }
}

.steps {
  &__add-register-button {
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: 8px 32px;
    border: dashed 2px $gray-600;
    color: $gray-600;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $blue;
      border-color: $blue;
    }
  }
}

.steps__success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  i {
    font-size: 42px;
    color: $green;
  }

  &-action {
    display: flex;
    justify-content: center;
  }
}

.modal-content-success {
  i {
    font-size: 42px;
    color: $green;
  }
}
