.fc .fc-multimonth-multicol {
  .fc-event-title {
    font-size: 6px !important;
  }

  .fc-daygrid-day-number {
    font-size: 8px !important;
    width: 16px;
    height: 16px;
  }
}

.fc .fc-bg-event {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $primary;
  opacity: 1;

  .fc-event-title {
    color: $white;
    opacity: 1;
    font-size: 12px;
    font-style: normal;
    background: none;
  }
}

.fc-daygrid-day-top {
  padding: 2px 2px 0 0;

  .fc-daygrid-day-number {
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    width: 24px;
    height: 24px;
    color: $black;
    background: $white;
    border-radius: 50%;
    text-align: center;
  }
}

.fc-event:hover,
.fc td:hover {
  cursor: pointer !important;
}

.fc .fc-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.fc .fc-button-active {
  background-color: $primary !important;
  border-color: $primary !important;
  opacity: 0.65;
}

.fc-daygrid-day-frame:has(.teste) .fc-daygrid-day-number {
  background: $primary !important;
  color: $white !important;
}

body[data-layout-mode="dark"] {
  .fc-daygrid-day-number {
    color: $black !important;
  }

  .fc-col-header-cell-cushion {
    color: $white !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-color: #272727 !important;
  }

  .fc .fc-button-primary {
    background-color: #272727 !important;
    border-color: #272727 !important;
  }

  .fc .fc-button-active {
    background-color: #1e1e1e !important;
    border-color: #1e1e1e !important;
  }

  .flatpickr-innerContainer {
    background-color: #272727;
  }

  .flatpickr-day.prevMonthDay {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  .flatpickr-day {
    color: $white !important;

    &.nextMonthDay {
      color: rgba(255, 255, 255, 0.3) !important;
    }

    &:hover {
      color: #1e1e1e !important;
      background-color: $white !important;
    }
  }
}
