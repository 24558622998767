.page-content {
  &__spinner {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    width: 100%;
  }
}

.spinner-block {
  display: flex;
  justify-content: center;
  width: 100%;
}

body[data-layout-mode="dark"] {
  .form-control:disabled,
  .form-select:disabled {
    background-color: #272727;

    &:hover {
      cursor: not-allowed;
    }
  }

  .text-muted {
    color: $form-select-color !important;
  }
}
