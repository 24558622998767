//
// _table.scss
//

.filter-by-button {
  border: none;
  background: none;
  color: $white;
}

.table {
  th {
    font-weight: $font-weight-bold;

    td {
      cursor: pointer;
    }
  }

  td {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}

.table-responsive {
  min-height: 400px;

  .dropdown-menu {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.175);
  }
}

.total-items {
  display: flex;
  align-items: flex-start;

  label {
    width: 300px;
    margin: 8px 0 0 0;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

.table tbody td {
  padding: 0.5rem 0.75rem;
}

body[data-layout-mode="dark"] {
  th td {
    color: $form-select-color !important;
  }
}

.table-draggable:hover {
  cursor: move;
}

.table-draggable:focus {
  cursor: pointer;
}

.table-drag-over {
  border-top: 2px dashed $primary !important;
  border-left: 2px dashed $primary !important;
  border-right: 2px dashed $primary !important;
}

.table-draggle-hover {
  border-left: 2px dashed $primary !important;
  border-right: 2px dashed $primary !important;
}

.table-draggable-active {
  border-top: 1px dashed #666 !important;
  border-left: 1px dashed #666 !important;
  border-right: 1px dashed #666 !important;
}

.table-draggable-active_tbody {
  border-left: 1px dashed #666 !important;
  border-right: 1px dashed #666 !important;
}

.table tbody tr:last-child .table-draggable-active_tbody {
  border-bottom: 1px dashed #666 !important;
}

.table tbody tr:last-child .table-draggle-hover {
  border-bottom: 2px dashed $primary !important;
}

body[data-layout-mode="light"] {
  .table-component-filter {
    height: 30px !important;
    background: rgba(255, 255, 255, 1) !important;
    color: rgb(30, 30, 30) !important;
  }
}

.table-expansible-row td {
  border: none;
  background-color: $dark !important;
}

.table-description {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: copy;
  }
}
