//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// auth 2

.auth-logo {
  .auth-logo-dark {
    display: $display-block;
  }
  .auth-logo-light {
    display: $display-none;
  }
}

.auth-body-bg {
  background-color: $card-bg;
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// authentication full page

.auth-full-bg {
  display: flex;

  @media (min-width: 1200px) {
    height: 100vh;
  }

  &::before {
    content: "";
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .bg-overlay {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &__background {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      opacity: 1;
      background: rgba(0, 0, 0, 1);
    }
  }
}

.account-pages {
  height: 100vh;
  padding-top: 3rem;
  background: linear-gradient(180deg, rgba(68, 118, 251, 1) 0%, rgba(0, 46, 190, 1) 100%);

  &__logo {
    display: flex;
    justify-content: center;
    padding: 1rem 0 0 0;
  }

  .text-white {
    color: #ffffff;
  }
}

.not-found-pages {
  height: 100vh;
  padding-top: 3rem;

  &__logo {
    display: flex;
    justify-content: center;
    padding: 1rem 0 0 0;
  }

  .text-white {
    color: #ffffff;
  }
}

.auth-full-page-content {
  display: flex;
  background-color: #f8f8fb;

  @media (min-width: 1200px) {
    min-height: 100vh;
  }
}

.auth-review-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: rgba($primary, 0.25);
        }
        &.active,
        &:hover {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.sgi-logo {
  &__title {
    font-family: "Mundial";
    font-size: 28px;
    font-weight: 600;
    font-size: normal;
    color: rgb(0, 0, 0);
    margin: 0;
  }

  &__subtitle {
    font-family: "Mundial";
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    margin: 0;
  }
}
